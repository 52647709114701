<template>
  <div class="services">
    <div class="services-landing">
      <img src="../assets/privateCover.jpeg" alt="Hvar private Tours">
      <div class="services-landing-overlay">
        <div class="services-landing-inner">
          <h1>Hvar Private Tours & Activities</h1>
        </div>
      </div>
    </div>
    <div class="services-inner-content" v-for="tour in myTours" v-bind:key="tour.id">
      <div class="inner-image">
        <img v-lazy="tour.image" alt="Hvar private tours">
      </div>
      <div class="inner-text">
        <h2>{{ tour.title }}</h2>
        <div class="line"></div>
        <p v-html="tour.content"></p>

        <button class="services-button" @click="show()">Make Enquiry</button>
      </div>
    </div>

  </div>

</template>

<script>
import tours from '../data/privateTours.json'
import Modal from "@/components/Modal";
export default {

  metaInfo(){
    return{
    title:' Amazing Hvar Private Tours & Activities | Blue Cave | Wine Tasting',
    meta:[
      { name: "robots", content: "index,follow" },
      {
        name:'description', content:'If you would like to explore beauties of Hvar while enjoying in your villa, these private tours to Blue cave, Wine taste, Zlatni rat  will blow you away!'
      }

    ],
    link:[
      { rel: 'favicon', href: 'favicon.ico' }
    ]
    }
  },
  data(){
    return{
      myTours: tours,
      loaded: false
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },

    show() {
      this.$modal.show(
          Modal
      );
    },
  }

}
</script>